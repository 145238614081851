import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import SliderArrow from "components/Icons/SliderArrow/slider-arrow"

const ArticleMainTail = ({ title, badge, excerpt, url, img }) => {
  return (
    <Link to={url} className="article-main-tail">
      <div className="article-main-tail__image">
        <img src={img?.sourceUrl} alt={img?.altText} />
        <span className="article-main-tail__badge">{badge}</span>
      </div>
      <div className="article-main-tail__content">
        <p className="article-main-tail__title">{title}</p>
        <div
          className="article-main-tail__excerpt"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
        <div className="article-main-tail__cta">
          <span className="article-main-tail__cta-text">
            Czytaj dalej
          </span>
          <div className="article-main-tail__cta-arrow">
            <SliderArrow />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ArticleMainTail
