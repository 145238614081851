import "./styles.scss"

import React from "react"

const content = [
  {
    image: {
      sourceUrl: require("assets/images/home/about/home-about--image-1.jpg")
        .default,
    },
    rows: [
      {
        title:
          "Medica per Pedes to nowoczesne Centrum Medyczne, w którym troszczymy się o zdrowie Twoich stóp z pasją i zaangażowaniem.",
        description:
          "<p>Naszą misją jest zapewnienie kompleksowej opieki dzięki wysoko wykwalifikowanej kadrze, złożonej z doświadczonych lekarzy medycyny, fizjoterapeutów, podologów oraz specjalistów ortopedycznych, reumatologicznych i dermatologicznych.</p>",
      },
      {
        title: "Profesjonalna i Troskliwa Opieka",
        description:
          "<p>W naszym Centrum zdrowie stóp i nóg to priorytet. Dbamy o każdego pacjenta indywidualnie, oferując profesjonalną opiekę opartą na najnowszych osiągnięciach medycyny. Nasza wyszkolona kadra specjalistów jest gotowa sprostać nawet najbardziej wymagającym przypadkom.</p>",
      },
    ],
  },
  {
    image: {
      sourceUrl: require("assets/images/home/about/home-about--image-2.jpg")
        .default,
    },
    rows: [
      {
        title: "Zespół Wybitnych Specjalistów",
        description:
          "<p>Nasz zespół to grupa ekspertów z różnych dziedzin medycyny. Współpracujemy z ortopedą, fizjoterapeutą, reumatologiem, podologiem i dermatologiem, aby dostarczyć kompleksową opiekę zdrowotną dla Twoich stóp i nóg. Dzięki tej multidyscyplinarnej współpracy możemy skutecznie diagnozować, leczyć i prowadzić profilaktykę różnorodnych schorzeń.</p>",
      },
    ],
  },
  {
    image: {
      sourceUrl: require("assets/images/home/about/home-about--image-3.jpg")
        .default,
    },
    rows: [
      {
        title: "Szeroka Gama Usług",
        description:
          "<p>W Medica per Pedes oferujemy kompleksowe usługi związane z leczeniem i profilaktyką chorób stóp i nóg. Nasze usługi obejmują diagnostykę, terapie ortopedyczne, fizjoterapię, zabiegi podologiczne oraz porady dermatologiczne. Niezależnie od problemu, z którym się zwracasz, nasz zespół specjalistów z przyjemnością Ci pomoże.</p><p>Indywidualne Podejście do Pacjenta Rozumiemy, że każdy przypadek jest unikalny. Dlatego też nasze podejście do pacjenta opiera się na indywidualnym planie leczenia i opiece. Nasz zespół zadba o to, abyś czuł się pewnie i komfortowo podczas każdej wizyty.</p><p>Zapraszamy do Medica per Pedes - miejscem, gdzie Twoje stopy są w dobrych rękach. Odkryj, jak możemy zadbać o zdrowie Twoich stóp i nóg. Skontaktuj się z nami już dziś i umów się na konsultację. Jesteśmy gotowi, byś znów cieszył się zdrowymi, sprawnymi stopami!</p>",
      },
    ],
  },
]

const About = ({ data }) => {
  const { clinicTitle } = data

  return (
    <section className="home-about">
      <div className="container">
        <p className="home-about__title">POZNAJ NASZĄ KLINIKĘ</p>
        <div
          className="home-about__heading"
          dangerouslySetInnerHTML={{ __html: clinicTitle }}
        />
        {content.map((item, index) => (
          <div
            key={index}
            className={`home-about__row row ${
              (index + 1) % 2 === 0 ? " home-about__row--reverse" : ""
            }`}
          >
            <div className="col-lg-6">
              <img
                className="home-about__image"
                src={item.image?.sourceUrl}
                alt=""
              />
            </div>
            <div className="col-lg-6">
              {item.rows?.length > 0 && (
                <div className="home-about__content">
                  {item.rows.map((item, index) => (
                    <div key={index} className="home-about__content-row">
                      {item.title && <h3>{item.title}</h3>}
                      {item.description && (
                        <div
                          className="home-about__content-description"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default About
