import "./styles.scss"

import React from "react"

const data = [
  {
    heading: "20",
    text: "lat pomagamy pacjentom",
  },
  {
    heading: "25 000",
    text: "wizyt zadowolonych klientów",
  },
  {
    heading: "80%",
    text: "klientów z polecenia",
  },
  {
    heading: "8",
    text: "specjalistów z pasją",
  },
]

const StatsBar = ({ stats }) => {
  return (
    <section className="home-stats">
      <div className="home-stats__wrapper">
        <div className="container">
          <div className="home-stats__numbers">
            <div className="row">
              {data.map((item, index) => (
                <div key={index} className="col-lg-3 col-6">
                  <div className="home-stats__numbers-item">
                    <p className="home-stats__numbers-counter">
                      <strong>{item.heading}</strong>
                    </p>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StatsBar
