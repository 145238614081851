import "./styles.scss"

import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper"
import { Link } from "gatsby"

import SliderArrow from "components/Icons/SliderArrow/slider-arrow"

const Offer = ({ offer }) => {
  SwiperCore.use([Autoplay, Navigation, Pagination])

  const offerSliderSettings = {
    loop: false,
    autoplay: {
      delay: 5000,
    },
    slidesPerView: 1,
    centeredSlides: false,
    speed: 700,
    scrollbar: {
      draggable: true,
    },
    spaceBetween: 30,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    pagination: {
      clickable: true,
      bulletClass: `home-offer__slider-bullets`,
    },
    navigation: {
      nextEl: ".home-offer__slider-arrow--next",
      prevEl: ".home-offer__slider-arrow--prev",
    },
  }

  return (
    <section className="home-offer">
      <div className="container">
        <p className="home-offer__title">SPRAWDŹ NASZĄ SZEROKĄ OFERTĘ</p>
        <h2 className="home-offer__heading">Jak możemy Ci pomóc</h2>
        <div className="home-offer__slider">
          <Swiper {...offerSliderSettings}>
            {offer?.map(({ acfTreatments, id, uri }) => {
              return (
                <SwiperSlide key={id}>
                  <Link to={uri}>
                    <div className="home-offer__slide">
                      <div className="home-offer__slide-img">
                        <img
                          src={acfTreatments?.thumbnail?.sourceUrl}
                          alt={acfTreatments?.thumbnail?.altText}
                        />
                      </div>
                      <div className="home-offer__slide-content">
                        <p className="home-offer__slide-heading">
                          {acfTreatments?.title}
                        </p>
                        <div className="home-offer__slide-cta">
                          <span className="home-offer__slide-cta-text">
                            Dowiedz się więcej
                          </span>
                          <div className="home-offer__slide-cta-arrow">
                            <SliderArrow />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              )
            })}
            <div className="home-offer__nav">
              <div className="home-offer__slider-arrows">
                <div className="home-offer__slider-arrow home-offer__slider-arrow--prev">
                  <SliderArrow />
                </div>
                <div className="home-offer__slider-arrow home-offer__slider-arrow--next">
                  <SliderArrow />
                </div>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default Offer
