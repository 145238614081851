import "./styles.scss"

import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectFade,
} from "swiper"

import SliderArrow from "components/Icons/SliderArrow/slider-arrow"

const data = [
  {
    id: "1",
    acfSlides: {
      image: {
        sourceUrl:
          require("assets/images/home/hero/home-hero--background-1.jpg")
            .default,
      },
      heading: "Zaufanie pacjentów",
      text: "Z roku na rok liczba zadowolonych pacjentów, którzy odwiedzają nasze Centrum Medyczne stale rośnie, o czym świadczą nasze pozytywne opinie.",
    },
  },
  {
    id: "2",
    acfSlides: {
      image: {
        sourceUrl:
          require("assets/images/home/hero/home-hero--background-2.jpg")
            .default,
      },
      heading: "Innowacyjne metody",
      text: "Naszym pacjentom zapewniamy kompleksową pomoc, poprzez zastosowanie najnowszych metod terapeutycznych i leczniczych z dziedziny podologii i medycyny.",
    },
  },
  {
    id: "3",
    acfSlides: {
      image: {
        sourceUrl:
          require("assets/images/home/hero/home-hero--background-3.jpg")
            .default,
      },
      heading: "Kompleksowe leczenie",
      text: "W Medica per Pedes każdy pacjent ma możliwość skorzystania kompleksowego leczenia u różnych specjalistów jeśli wymaga tego dana dolegliwość.",
    },
  },
  {
    id: "4",
    acfSlides: {
      image: {
        sourceUrl:
          require("assets/images/home/hero/home-hero--background-4.jpg")
            .default,
      },
      heading: "Profesjonalizm i doświadczenie",
      text: "Nasi specjaliści to skuteczni i wieloletni praktycy, którzy zdiagnozują i dostosują najskuteczniejszą terapię, aby zlikwidować dokuczliwy problem.",
    },
  },
  {
    id: "5",
    acfSlides: {
      image: {
        sourceUrl:
          require("assets/images/home/hero/home-hero--background-5.jpg")
            .default,
      },
      heading: "Zdrowie i bezpieczeństwo",
      text: "Najważniejszy jest dla nas pacjent, dlatego pracujemy na najwyższej jakości nowoczesnych sprzętach, urządzeniach i preparatach.",
    },
  },
]

const Hero = ({ slides }) => {
  SwiperCore.use([Autoplay, Navigation, Pagination, EffectFade])

  const heroSliderSettings = {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    slidesPerView: 1,
    centeredSlides: true,
    speed: 700,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    scrollbar: {
      draggable: true,
    },
    spaceBetween: 0,
    pagination: {
      clickable: true,
      bulletClass: `home-hero__slider-bullets`,
    },
    navigation: {
      nextEl: ".home-hero__slider-arrow--next",
      prevEl: ".home-hero__slider-arrow--prev",
    },
  }
  
  return (
    <section className="home-hero">
      <div className="home-hero__slider">
        <Swiper {...heroSliderSettings}>
          {data?.map(({ acfSlides, id }) => {
            return (
              <SwiperSlide key={id}>
                <div
                  className="home-hero__slide"
                  style={{
                    backgroundImage: `url(${acfSlides.image?.sourceUrl})`,
                  }}
                >
                  <div className="container">
                    <div className="home-hero__slide-content">
                      <h1>{acfSlides.heading}</h1>
                      <p>{acfSlides.text}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
          <div className="home-hero__nav">
            <div className="container">
              <div className="home-hero__slider-arrows">
                <div className="home-hero__slider-arrow home-hero__slider-arrow--prev">
                  <SliderArrow />
                </div>
                <div className="home-hero__slider-arrow home-hero__slider-arrow--next">
                  <SliderArrow />
                </div>
              </div>
            </div>
          </div>
        </Swiper>
      </div>
    </section>
  )
}

export default Hero
