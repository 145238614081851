import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/navigation"
import "swiper/css/effect-fade"
import "swiper/css/pagination"

import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import NewsSection from "components/NewsSection"
import TestimonialsSlider from "components/TestimonialsSlider"
import { Hero, StatsBar, Medicine, About, Offer } from "page_components/home"

const Home = ({ data }) => {
  const home_content = data?.homePage?.nodes?.[0]?.acfHomePage

  return (
    <Layout
      seo={{
        title: "Podolog - Specjalistyczny Gabinet Podologiczny ",
        description:
          "W naszym gabinecie znajdziesz fachową pomoc, gdzie wykwalifikowani i kompetentni specjaliści zdiagnozują zmianę i dostosują najskuteczniejszą terapię.",
      }}
    >
      <Hero slides={data?.allWpSlide?.nodes} />
      <StatsBar stats={home_content} />
      <Medicine />
      <About data={home_content} />
      <Offer offer={data?.offerSlider?.nodes} />
      <TestimonialsSlider
        title="PRZEKONAJ SIĘ DLACZEGO WARTO NAM ZAUFAĆ"
        show_more_btn={false}
      />
      <NewsSection
        title="POSZERZ SWOJĄ WIEDZĘ"
        heading="Blog"
        posts={data?.newPosts?.nodes}
      />
      {/* <PromoModal
        title={home_content?.promoTitle}
        description={home_content?.promoDesc}
        image={home_content?.promoImage}
      /> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpSlide {
      nodes {
        acfSlides {
          heading
          image {
            altText
            sourceUrl
          }
          text
        }
        id
      }
    }
    newPosts: allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        id
        slug
        acfPosts {
          image {
            altText
            sourceUrl
          }
        }
        title
        excerpt
      }
    }
    homePage: allWpPage(filter: { id: { eq: "cG9zdDoxNzQ=" } }) {
      nodes {
        acfHomePage {
          counter1
          counter2
          counter3
          counter4
          promoDesc
          promoTitle
          wpFields {
            specialization
          }
          promoImage {
            altText
            sourceUrl
          }
          clinicDesc
          clinicTitle
          clinicImage {
            altText
            sourceUrl
          }
        }
      }
    }
    offerSlider: allWpTreatment(limit: 10) {
      nodes {
        id
        acfTreatments {
          info
          title
          thumbnail {
            altText
            sourceUrl
          }
        }
        uri
      }
    }
  }
`

export default Home
