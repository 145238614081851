import "./styles.scss"

import React from "react"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination } from "swiper"

import SliderArrow from "components/Icons/SliderArrow/slider-arrow"

const data = [
  {
    image: require("assets/images/home/medicine/home-medicine--image-1.jpg")
      .default,
    title: "Podologia",
  },
  {
    image: require("assets/images/home/medicine/home-medicine--image-2.jpg")
      .default,
    title: "Ortopedia",
  },
  {
    image: require("assets/images/home/medicine/home-medicine--image-3.jpg")
      .default,
    title: "Fizjoterapia",
  },
  {
    image: require("assets/images/home/medicine/home-medicine--image-4.jpg")
      .default,
    title: "Podochirurgia",
  },
  {
    image: require("assets/images/home/medicine/home-medicine--image-5.jpg")
      .default,
    title: "Dermatologia",
  },
  {
    image: require("assets/images/home/medicine/home-medicine--image-6.jpg")
      .default,
    title: "Poradnia stopy cukrzycowej",
  },
]

const Medicine = () => {
  SwiperCore.use([Navigation, Pagination])

  const medicineSliderSettings = {
    className: "home-medicine__slider",
    slidesPerView: 1,
    speed: 700,
    spaceBetween: 30,
    pagination: {
      clickable: true,
      bulletClass: "home-medicine__slider-bullets",
    },
    navigation: {
      nextEl: ".home-medicine__slider-arrow--next",
      prevEl: ".home-medicine__slider-arrow--prev",
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1201: {
        slidesPerView: 4,
      },
    },
  }

  return (
    data?.length > 0 && (
      <section className="home-medicine">
        <div className="container">
          <Swiper {...medicineSliderSettings}>
            {data.map((item, index) => {
              const ElementType = item.to ? Link : "div"

              return (
                <SwiperSlide key={index}>
                  <ElementType
                    className="home-medicine__item"
                    {...(item.to ? { to: item.to } : {})}
                  >
                    <span
                      className="home-medicine__item-image"
                      style={{ backgroundImage: `url(${item.image})` }}
                    />
                    <span className="home-medicine__item-title">
                      {item.title}
                    </span>
                  </ElementType>
                </SwiperSlide>
              )
            })}
            <SwiperSlide />
            <div className="home-medicine__nav">
              <div className="home-medicine__slider-arrows">
                <div className="home-medicine__slider-arrow home-medicine__slider-arrow--prev">
                  <SliderArrow />
                </div>
                <div className="home-medicine__slider-arrow home-medicine__slider-arrow--next">
                  <SliderArrow />
                </div>
              </div>
            </div>
          </Swiper>
        </div>
      </section>
    )
  )
}

export default Medicine
